<template>  
 <CRow class="mt-5 row justify-content-md-center" >
    <CCol md="3"><div class="ml-2"><h4><strong >帳號設定</strong></h4><h6>管理您的帳號詳細資訊</h6></div></CCol>
    <CCol md="8">
     <CCard> 
        <CCardBody> 
            <CRow>
                <CCol > 
                    <table class="table table-clear"  >
                            <tr>
                                <td class="text-muted"  ><strong>用戶ID:</strong></td>
                                <td><strong> {{username}}</strong></td>
                            </tr>
                            <tr>
                                <td class="text-muted"><strong>狀態:</strong></td>
                                <td><strong>{{getUserStatus}}</strong></td>
                            </tr>  
                    </table>  
                </CCol>
            </CRow>
            <CRow>    
                <CCol  > 
                    <br><br>
                    <form @submit.prevent="updateProfile">   
                        <h6><strong>重置一組新密碼</strong></h6>
                        <CInput placeholder="新密碼"
                                type="password"  
                                autocomplete="new-password" v-model="password" >
                            <template #prepend-content>
                                <CIcon name="cil-lock-locked" />
                            </template>
                        </CInput>
                        <CInput placeholder="確認新密碼" v-model="repassword" 
                                type="password"
                                autocomplete="new-password"
                                class="mb-4">
                            <template #prepend-content>
                                <CIcon name="cil-lock-locked" />
                            </template>
                        </CInput> 
                        <h6><strong>修改資料</strong></h6>
                        <CInput placeholder="公司" v-model="company">
                            <template #prepend-content>
                                <CIcon name="cil-building" />
                            </template>
                         </CInput>  
                        <CInput placeholder="姓名" v-model="name">
                            <template #prepend-content>
                                <CIcon name="cil-user" />
                            </template>
                         </CInput>  
                        <CInput placeholder="電話" v-model="phone" >
                             <template #prepend-content>
                                <CIcon name="cil-phone" />
                            </template>
                         </CInput>  
                         <br>
                        <button type="submit" class="btn btn-success btn-block text-white ">送出</button>  
                         <br>
                    </form>  
                </CCol>
            </CRow> 
        </CCardBody>
    </CCard>
         </CCol>
 </CRow>       
 
</template>       

<script>
import axios from 'axios';
import appSettings from '../../util/settings';
import store from '../../util/store';
import dialog from '../../util/dialog';

export default {
    data() {
        return {
            username:'',
            userstatus:'',
            password:'',
            repassword:'',
            name:'',
            company:'',
            phone:''
        }
    }, 
     mounted(){
         axios.post(appSettings.MyInfo, 
            {
                userToken:store.state.token, 
            })
            .then(this.setMyIfo) 
            .catch(function (error) { 
                console.log(error);  
            });
     },
     computed:{
        getUserStatus:function(){
            return this.userstatus == 1 ? "啟用":"未啟用"
        }
     },
     methods: { 
        setMyIfo(response){               
            var data = response.data;
            if(!data.IsLogin){                    
                store.commit("clearUser");
                this.$router.replace({ name: "Login" });
            }
            else{
                var result = data.Result; 
                this.username = result.UserId;
                this.userstatus = result.Status;
                this.company = result.Organization;
                this.phone = result.Phone;
                this.name = result.Name;
                //console.log(data);
            }               
        },
        updateResult(response){   
            var data = response.data;
            if(data.IsSuccess){
                dialog.showInforDialog("個人資料修改成功");
            }
            else{
                dialog.showInforDialog(data.Message);
            }
        },
        updateProfile(){
            var errorMessage = '';
            
            if(this.password !== this.repassword){
                errorMessage += '兩次密碼不一致<br>';
            }

            if(errorMessage === ''){
                let password = "";

                // if(this.password !== ''){
                //     password = CryptoJS.encrypt(this.password);
                // }

                password = this.password;

                axios.post(appSettings.UpdateMyInfo, 
                    {
                        userToken:store.state.token,
                        password,
                        name:this.name,
                        company:this.company,
                        phone:this.phone
                    })
                    .then(this.updateResult)
                    .catch(function (error) { 
                        dialog.showInforDialog(error);
                        console.log(error);
                });
            }
            else{
                 dialog.showInforDialog(errorMessage);
            }
        }
     }
}
</script>